import React from "react";
import { graphql, Link } from "gatsby";

import { openPopupWidget } from "react-calendly";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import Layout from "../components/Layout";

import { Section, Hero } from "../components/bulma";

// import Hero from "../components/Hero";
import Blurbs from "../components/Blurbs";
import ContentSection from "../components/ContentSection";
import FinalSection from "../components/FinalSection";
// import BlogSection from '../components/BlogSection';
import CTA from "../components/CTA";
import SEO from "../components/SEO/SEO";

const url = "https://calendly.com/fastlabs/free-consultation";

const onClick = () => openPopupWidget({ url });

export default function CaseStudyPage({ data }) {
  const { markdownRemark: page } = data;
  const {
    image,
    title,
    description,
    slug,
    heading,
    subheading,
    sections,
    blurbs,
    final,
  } = page.frontmatter;

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        // image={image.childImageSharp.sizes.src}
        pathname={slug}
        article
      />
      <CaseStudyPageTemplate
        image={image}
        heading={heading}
        subheading={subheading}
        blurbs={blurbs}
        sections={sections}
        final={final}
      />
    </Layout>
  );
}

export const CaseStudyPageTemplate = ({
  image,
  heading,
  subheading,
  sections,
  blurbs,
  final,
}) => (
  <div>
    <Hero>
      <div className="columns is-vcentered">
        <div className="column is-6-desktop p-6">
          <h1 className="title is-1 is-hidden-mobile  has-text-weight-bold is-spaced">
            {heading}
          </h1>
          <h1 className="title is-hidden-desktop is-size-2-mobile has-text-weight-bold is-spaced">
            <br />
            {heading}
          </h1>
          <h2 className="subtitle  is-size-4-mobile is-size-4">{subheading}</h2>

          <div className="buttons">
            <p className="control">
              <button
                className="button is-primary is-medium is-rounded"
                onClick={onClick}
              >
                <strong>TALK TO EXPERT</strong>
              </button>
            </p>

            <p className="control">
              <Link
                to="/pricing"
                className="button is-text is-medium is-rounded"
              >
                View pricing
              </Link>
            </p>
            <p>Book a free, no-obligation consultation now!</p>
          </div>
        </div>
        <div className="column is-5">
          <PreviewCompatibleImage imageInfo={image} />
        </div>
      </div>
      <Blurbs box items={blurbs} />
    </Hero>

    <Section>
      <ContentSection items={sections} box />
    </Section>

    <Section>
      <CTA />
    </Section>
  </div>
);

export const pageQuery = graphql`
  query CaseStudyPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        heading
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        blurbs {
          image {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          title
          text
        }
        sections {
          title
          text
          color
          side
          image {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        final {
          title
          text
          Image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
